import React, { useContext, useEffect } from 'react';
import appContext from '../Context/appContext';
import { useDeliveryDetails } from '../hooks/app';
import notificationTypes from '../constants/modalNotificationConst';
import { isNewHomePageApplicable } from '../constants/feature';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../redux/slices/modalSlice';
import { SCHEDULE } from '../constants/deliveryType';
function toMonthAndDay(dateString) {
  const date = new Date(dateString);
  const options = { month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export default function DeliveryTimingSchedule({ setShowNextWindow, fullText, showNextWindow, onlyText, currentTab }) {
  const {
    data: { scheduleDeliveryMessage, noScheduleSlotsMessage, deliveryDays }
  } = useDeliveryDetails(false);
  const dispatchRedux = useDispatch()
  const notifications = useSelector(state => state.modal.notifications)
  const {
    deliveryDetails: {
      deliveryDay,
      deliverySlot
    }
  } = useSelector(state => state.user)
  function isSlotAvailable() {
    // Find the day element matching the deliveryDay
    const dayElement = deliveryDays.find((day) => {
      const formattedDay = `${day.day}${day.showDate ? ` ( ${toMonthAndDay(day.date)} )` : ''}`;
      return formattedDay === deliveryDay;
    });

    // If the day is not found, the slot is unavailable
    if (!dayElement) {
      return false;
    }

    // Check if the slot exists in the deliveryWindows of the matched day
    const isSlotFound = dayElement.deliveryWindows.some((item) => item.slot === deliverySlot);

    return isSlotFound; // Return true if slot exists, false otherwise
  }



  const showAvailableDelivery = () => {
    const scheduleSlotOpen = notifications.map(notification => notification.type).includes(notificationTypes.scheduleSlot);
    if (!scheduleSlotOpen) {
      dispatchRedux(pushNotification({
        type: notificationTypes.scheduleSlot,
        data: { noScheduleSlotsMessage, hideRadioButtons: !isNewHomePageApplicable }
      }))
    }
  };
  if (fullText) {
    return <>
      <span className='hidden md:block'>Scheduled mode lets you pick a delivery time that suits you</span>
      <span className='md:hidden'>Pick a delivery time that suits you.</span>
    </>
  }
  return scheduleDeliveryMessage
    ? scheduleDeliveryMessage && (
      onlyText ?
        <span className='cursor-pointer relative font-normal-delivery-date'>{deliveryDay && deliverySlot && isSlotAvailable() ? `${deliveryDay?.replace("Tomorrow", "Tmrw")}, ${deliverySlot?.replace(/(\(.*?\)) */g, '')}` : `${scheduleDeliveryMessage?.replace("Tomorrow", "Tmrw")}`}
          <span
            // style={{ color: currentTab === SCHEDULE ? '#ffffff' : 'text-secondary-color' }}
            className={`absolute right-[-5px] transform translate-x-[100%] top-[50%] translate-y-[-50%] ${currentTab === SCHEDULE ? 'text-[#ffffff]' : 'text-secondary-color'}  !important ml-1 icon-down`} /></span>
        :

        <div className='delivery-message-wrapper'>
          <div className="delivery-timing schedule-info d-flex  d-timing d-align-items-center">
            <p className="mb-0 next-slot d-flex flex-column">
              Next Delivery Window:
              <span className="next-slot-time">{scheduleDeliveryMessage}</span>
            </p>
            <a onClick={showAvailableDelivery} className="mb-0 time-table">
              Select a Delivery Window
            </a>
          </div>
          <button onClick={() => setShowNextWindow(!showNextWindow)} className='btn icon-close'></button>
        </div>
    )
    : onlyText ? "Checking..." : null
}
